import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.url
const password = `${HOST}password`

export default {
  verifyStatusToken(context) {
    axios.post(`${password}/status`, context.activateStatusRequest).then(response => {
      context.$vs.loading.close()
      context.setStatusActivated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.setStatusNotActivated(error.response.data.return_message)
    })
  },
  verifyResetToken(context, token1, name1) {
    const params = { params: { token: token1, name: name1 } }
    axios.get(password, params).then(response => {
      context.$vs.loading.close()
      context.setStatusVerify(response.data)
    }, error => {
      context.$vs.loading.close()
      context.setStatus(error.response.data.return_message)
    })
  },
  getCompanyUsingDns(context1, dns) {
    const context = context1
    const params = { params: {} }
    axios.get(`${password}/company-name/${dns}`, params).then(response => {
      context.$vs.loading.close()
      context.$store.commit('UPDATE_COMPANY_NAME', response.data.company_name)
      context.$store.commit('UPDATE_COMPANY_LOGO', response.data.company_image_url)
    }, () => {
      context.$vs.loading.close()
    })
  },
  createPassword(context, request) {
    axios.put(`${password}/create-password`, request).then(response => {
      context.$vs.loading.close()
      context.setCreatePasswordSent(response.data)
    }, error => {
      context.$vs.loading.close()
      context.setCreatePasswordNotSent(error.response.data.return_message)
    })
  },
  forgotPassword(context) {
    axios.post(password, context.forgotPassword).then(response => {
      context.$vs.loading.close()
      context.setForgotRequestSent(response.data)
    }, error => {
      context.$vs.loading.close()
      context.setForgotRequestNotSent(error.response.data.return_message)
    })
  },
}
