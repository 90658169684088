<template>
  <b-img
    :src="$store.state.company_logo"
    rounded
    width="162px"
  />
</template>

<script>
import {
  BImg,
} from 'bootstrap-vue'

export default ({
  components: {
    BImg,
  },
})
</script>
